import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { Menu } from "@headlessui/react";

function MenuItemDropdown({ item, label, source, headerTransparent }) {

  const location = typeof window !== "undefined" ? window.location : null;
  const [itemOpen, setItemOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(false)

  const DropdownMenuItem = ({ item, type }) => {
    let itemId = null
    let title = item.name
    let slug = item.url



    if (source && source == 'mobilemenu') {
      return (<Link
        to={`${slug && slug.length < 2 ? "" : slug}`}
        className="block py-2 px-2 hover:underline font-normal"
      >
        {item.name}
      </Link>)
    }
    return (<Menu.Item className="block p-4 font-normal hover:bg-gray-50 focus:outline-none focus:bg-gray-50  text-gray-700 hover:text-brand-600 transition-colors duration-150 ease-in">
      {({ active }) => (
        <Link to={`${slug}`} title={title ? title : item.name}>
          {item.name}
        </Link>
      )}
    </Menu.Item>)
  }

  useEffect(() => {
    item && item.submenu[0] && item.submenu[0].menuItems.forEach(element => {
      if (location.pathname && element.url.includes(location.pathname) && location.pathname.length > 2) {
        setActiveMenu(true)
      }
    });

  }, [])


  if (source && source == 'mobilemenu') {
    return <div className="py-4 px-8  border-b border-gray-300">
      <span className={`font-medium mb-2 block ${activeMenu && 'text-orange-600'}`}>{label}</span>
      {item.submenu[0].menuItems.map((item, i) => (
        <DropdownMenuItem key={item.id} item={item} type={item.type} />
      ))}
    </div>
  }




  return (
    <div className={`${headerTransparent ? 'text-white hover:text-brand-200' : 'text-gray-800 hover:text-brand-600'} relative inline-block text-left font-normal text-base transition-colors duration-150 ease-in`} data-type={item.dropdownonesection} >


      <Menu>
        <Menu.Button className={`${activeMenu && 'bg-black/50'} flex items-center font-medium px-4 py-3 rounded-xl    focus:outline-none button-active-bhvr`}>
          {({ open }) => (
            <>
              <span>{label}</span>
              <svg
                className={`w-5 h-5 opacity-75 rotate-0  duration-75 transition-transform ease-in ${open ? "transform rotate-180" : ""
                  }`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </>
          )}
        </Menu.Button>

        <Menu.Items className="absolute left-0   origin-top-left bg-white border border-gray-200 divide-y divide-gray-50 rounded-md shadow-lg outline-none" style={{ width: '20rem' }}>
          {item.submenu[0].menuItems.map((item, i) => (
            <DropdownMenuItem key={item.id} item={item} type={item.type} />
          ))}
        </Menu.Items>
      </Menu>
    </div>
  );
}

export default MenuItemDropdown;
